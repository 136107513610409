import React, { useRef, useState, useEffect } from "react";
import useMouseParallax from "../hooks/useMouseParallax";

const BackgroundScene = () => {
  const sceneRef = useRef(null);
  const { x, y } = useMouseParallax();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth < 768);

      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  if (isMobile) {
    return null;
  }

  return (
    <div
      style={{ transform: `translate(${x}px, ${y}px)` }}
      ref={sceneRef}
      className="scene__wrapper"
      aria-hidden="true"
    >
      <div id="circles-scene" className="scene">
        <div
          style={{ transform: `translate(${x}px, ${y}px)` }}
          ref={sceneRef}
          className="scene__layer"
          data-depth="0.4"
        >
          <div className="circle solids" />
        </div>
        <div className="scene__layer" data-depth="0.8">
          <div className="circle stripes" />
        </div>
      </div>
    </div>
  );
};

export default BackgroundScene;
